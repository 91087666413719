import React from 'react';
import {graphql} from 'gatsby';

import ArticleContent from '../components/ArticleContent';
import Layout from '../components/Layout';

const Article = ({
    data,
    pageContext: {slug, description, from, keywords, lang, title, url},
}) => (
    <Layout
        description={description}
        keywords={keywords}
        lang={lang}
        name='article'
        path={from}
        title={title}
        url={url}
    >
        <ArticleContent article={data.markdownRemark} lang={lang} slug={slug} alone={true} />
    </Layout>
);

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: {slug: {eq: $slug}}) {
            frontmatter {
                audio {
                    file
                    label {
                        de
                        en
                        pl
                    }
                }
                authors {
                    firstName
                    lastName
                    orcid
                    affiliation {
                        de
                        en
                        pl
                    }
                }
                date
                doi
                firstPage
                issue
                keywords {
                    de
                    en
                    pl
                }
                item_abstracts {
                    de
                    en
                    pl
                }
                lastPage
                name
                path
                pdf
                title
                title_en
                type
                year
            }
            html
            rawMarkdownBody
            excerpt
        }
    }
`;

export default Article;
